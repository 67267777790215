import {
  Snip721Contract,
  createContractClient,
  snip721PermitDef,
  ContractDefinition,
  Context,
  ContractMessageRequest,
  extendContract,
  ContractMessageResponse,
} from "@stakeordie/griptape.js";

const address = process.env.VUE_APP_ANONS_CONTRACT;
const codeHash = process.env.VUE_APP_ANONS_CONTRACT_HASH;

export interface AnonsContract extends Snip721Contract {
  migrate(contract: string, token_id: string, msg: string): Promise<
    ContractMessageResponse<{
      send_nft: {
        status: string
      }
    }>
  >;
}

const anonsContractDef: ContractDefinition = {
  messages: {
    migrate(_: Context, contract: string, token_id: string, msg?: string ): ContractMessageRequest {
      const handleMsg = {
        send_nft: {
          contract,
          token_id,
          msg,
        },
      };
      return { handleMsg, fees: 400_000 };
    }
  },
};

export const anonsContract = 
  createContractClient<AnonsContract>({
    id: 'anons',
    at: address,
    definition: extendContract(snip721PermitDef, anonsContractDef),
    codeHash,
  });
