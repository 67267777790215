import {
  Snip721Contract,
  createContractClient,
  ContractDefinition,
  ContractQueryRequest,
  ContractMessageRequest,
  Context,
  extendContract,
  ContractMessageResponse,
  snip721PermitDef,
} from "@stakeordie/griptape.js";

export interface S2Contract extends Snip721Contract {
  getHandles(tokenIds: string[]): Promise<{
    get_handles: {
      values: HandleS2[];
    };
  }>;
  getHandlesWithPermit(tokenIds: string[]): Promise<{
    get_handles: {
      values: HandleS2[];
    };
  }>;
  setHandle(
    tokenId: string,
    handle: string
  ): Promise<
    ContractMessageResponse<{
      set_handle: {
        status: string;
      };
    }>
  >;
  merge(
    contract: string,
    tokenId: string,
    msg: string
  ): Promise<
    ContractMessageResponse<{
      send_nft: {
        status: string;
      };
    }>
  >;
}

export interface HandleS2 {
  token_id: string;
  handle: string;
}

const address = process.env.VUE_APP_ANONS_S2_CONTRACT;
const codeHash = process.env.VUE_APP_ANONS_S2_CONTRACT_HASH;

const anonsS2ContractDef: ContractDefinition = {
  queries:  {
    getHandles({ address, key }: Context, tokenIds: string[]): ContractQueryRequest {
      return {
        get_handles: {
          token_ids: tokenIds,
          viewer: {
            address: address,
            viewing_key: key,
          }
        }
      };
    },
    getHandlesWithPermit({ permit }: Context, tokenIds: string[]): ContractQueryRequest {
      const query = {
        get_handles: {
          token_ids: tokenIds,
        }
      };
      
      return {
        with_permit: {
          query,
          permit,
        }
      };
    },
  },
  messages: {
    setHandle(_: Context, tokenId: string, handle: string ): ContractMessageRequest {
      const handleMsg = {
        set_handle: {
          token_id: tokenId,
          handle,
        }
      };
      return { handleMsg, fees: 150_000 };
    },
    reveal(_: Context, tokenId: string): ContractMessageRequest {
      const handleMsg = {
        reveal: {
          token_id: tokenId,
        }
      };
      return { handleMsg, fees: 150_000 };
    },
    merge(_: Context, contract: string, token_id: string, msg?: string ): ContractMessageRequest {
      const handleMsg = {
        send_nft: {
          contract,
          token_id,
          msg,
        },
      };
      return { handleMsg, fees: 500_000 };
    }
  },
};

export const s2AnonsContract = 
  createContractClient<S2Contract>({
    id: 'anons-s2',
    at: address,
    definition: extendContract(snip721PermitDef, anonsS2ContractDef),
    codeHash,
  });
