import { s1AnonsContract } from "@/contracts/s1-nft-contract";
import { s2AnonsContract } from "@/contracts/s2-nft-contract";
import { collections, S1_COLLECTION, S2_COLLECTION } from "@/data";

export function getContract(collection: string) {
  if (collection === S1_COLLECTION) return s1AnonsContract;
  if (collection === S2_COLLECTION) return s2AnonsContract;
  throw new Error("No permit manager for collection " + collection);
}

export function numberWithCommas(x: number | string) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function getCollectionLabel(value: string) {
  return collections.find(it => it.value === value)?.label;
}

export function getCollectionValue(contract: string): string {
  if (contract === process.env.VUE_APP_ANONS_S1_CONTRACT) return "s1";
  if (contract === process.env.VUE_APP_ANONS_S2_CONTRACT) return "s2";
  throw new Error("Contract does not belong to a S1 or S2");
}
