import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./styles/index.css";
import { gripApp, getKeplrAccountProvider } from "@stakeordie/griptape.js";
import { createPinia } from "pinia";
import VueLazyLoad from "vue3-lazyload";
import "@/contracts";
import "@/stores/wallet-store"; 

const restUrl = process.env.VUE_APP_REST_URL;
const provider = getKeplrAccountProvider();

const app = createApp(App)
  .use(router)
  .use(createPinia())
  .use(VueLazyLoad);

function runApp() {
  app.mount("#app");
}

gripApp(restUrl, provider, runApp);
