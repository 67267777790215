import {
  bech32,
  bootstrap,
  coinConvert,
  getAddress,
  getNativeCoinBalance,
  onAccountAvailable,
  onAccountNotAvailable,
  onAccountDisconnect,
} from "@stakeordie/griptape.js";
import { ref } from "vue";
import { defineStore } from "pinia";
import { sscrtContract } from "@/contracts/sscrt";
import { keplrViewingKeyManager } from "@stakeordie/griptape.js";
import { numberWithCommas } from "@/utils";

export const useWalletStore = defineStore("wallet-store", () => {
  const loading = ref(true);
  const address = ref<string>();
  const connected = ref(false);
  const balance = ref<string>();
  const sscrtBalance = ref<string>();
  const hasViewingKey = ref(false);
  const wrongViewingKey = ref(false);

  async function connect() {
    loading.value = true;
    try {
      await bootstrap();
      connected.value = true;
    } catch (e) {
      console.error(e);
      connected.value = false;
    } finally {
      loading.value = false;
    }
  }

  async function createViewingKey() {
    await keplrViewingKeyManager.add(sscrtContract);
    try {
      await getSscrtBalance();
      hasViewingKey.value = true;
    } catch (e) {
      wrongViewingKey.value = true;
    }
  }

  async function getSscrtBalance() {
    const response = await sscrtContract.getBalance();
    const balance = coinConvert(response.balance.amount, 6, "human", 2);
    sscrtBalance.value = numberWithCommas(balance);
  }

  return {
    loading,
    address,
    connected,
    balance,
    bootstrap,
    connect,
    sscrtBalance,
    createViewingKey,
    hasViewingKey,
    getSscrtBalance,
    wrongViewingKey,
  };
});

const removeOnConnect = onAccountAvailable(async () => {
  const store = useWalletStore();
  const rawAddress = getAddress();
  if (rawAddress) {
    store.address = bech32(rawAddress, 12);
  }

  let balance = await getNativeCoinBalance();
  balance = coinConvert(balance, 6, "human", 2);
  store.balance = numberWithCommas(balance);

  const key = keplrViewingKeyManager.get(sscrtContract.at);
  if (key) {
    try {
      await store.getSscrtBalance();
      store.hasViewingKey = true;
    } catch (e: unknown) {
      store.wrongViewingKey = true;
    }
  }

  store.connected = true;
  store.loading = false;
  if (removeOnConnect) removeOnConnect();
});

const removeOnNotConnect = onAccountNotAvailable(() => {
  const store = useWalletStore();
  store.loading = false;
  store.connected = false;
  if (removeOnNotConnect) removeOnNotConnect();
});

const removeOnDisconnect = onAccountDisconnect(() => {
  const store = useWalletStore();
  store.connected = false;
  if (removeOnDisconnect) removeOnDisconnect();
});
