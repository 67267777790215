import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    name: "home",
    path: "/",
    component: () => import("@/pages/HomePage/HomePage.vue"),
  },
  {
    name: "gallery",
    path: "/gallery/:collectionId",
    component: () => import("@/pages/GalleryPage/GalleryPage.vue"),
  },
  {
    name: "provenance",
    path: "/provenance",
    component: () => import("@/pages/ProvenancePage/ProvenancePage.vue"),
  },
  {
    name: "anon",
    path: "/anon/:contract/:id",
    component: () => import("@/pages/AnonNoPage/AnonNoPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
