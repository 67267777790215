export const icons = {
  backgrounds: "background",
  baseLayer: "base-layer",
  basePerson: "base-anon",
  body: "base-anon",
  clothes: "base-layer",
  ears: "ears",
  eyes: "eyes",
  head: "head",
  headItem: "head",
  mouth: "mouth",
  neck: "neck",
  tattoos: "tattoos",
  topLayer: "top-layer",
  badge: "badges",
  augmented: "augmented",
};

export const traitNames = {
  backgrounds: "Background",
  baseLayer: "Base Layer",
  basePerson: "Base Anon",
  body: "Body",
  clothes: "Clothes",
  ears: "Ears",
  eyes: "Eyes",
  head: "Head",
  headItem: "Hat",
  mouth: "Mouth",
  neck: "Neck",
  tattoos: "Tattoos",
  topLayer: "Top Layer",
  badge: "Badge",
  augmented: "Augmented",
};

export const S1_COLLECTION = "s1";
export const S2_COLLECTION = "s2";

export const collections = [
  {
    value: S1_COLLECTION,
    label: "OG Anons"
  },
  {
    value: S2_COLLECTION,
    label: "The Army"
  },
];
