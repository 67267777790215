import {
  snip20Def as baseSnip20Def,
  Snip20Contract,
  createContractClient,
  ContractDefinition,
  ContractMessageResponse,
  Context,
  extendContract,
  ContractMessageRequest,
} from "@stakeordie/griptape.js";

interface SSCRTContract extends Snip20Contract {
  send(msg?: string): Promise<
    ContractMessageResponse<{
      send: {
        status: string;
      };
    }>
  >;
}

const snip20Addr = process.env.VUE_APP_SNIP20_CONTRACT_ADDRESS;
const minterAddr = process.env.VUE_APP_MINTER_CONTRACT_ADDRESS;

const sscrtDef: ContractDefinition = {
  messages: {
    send({ padding }: Context, msg?: string): ContractMessageRequest {
      const theMsg = msg ? btoa(msg) : undefined;
      const handleMsg = {
        send: {
          recipient: minterAddr,
          amount: process.env.VUE_APP_MINT_PRICE,
          padding,
          msg: theMsg,
        },
      };
      return { handleMsg, fees: 650_000 };
    },
  },
};

export const sscrtContract = createContractClient<SSCRTContract>({
  id: "sscrt",
  at: snip20Addr,
  definition: extendContract(baseSnip20Def, sscrtDef),
});
