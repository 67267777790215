import {
  Snip721Contract,
  createContractClient,
  ContractDefinition,
  ContractQueryRequest,
  ContractMessageRequest,
  Context,
  extendContract,
  ContractMessageResponse,
  snip721PermitDef,
  Metadata,
  Expiration,
} from "@stakeordie/griptape.js";

export interface S1Contract extends Snip721Contract {
  getHandles(tokenIds: string[]): Promise<{
    get_handles: {
      values: Handle[];
    };
  }>;
  getHandlesWithPermit(tokenIds: string[]): Promise<{
    get_handles: {
      values: Handle[];
    };
  }>;
  getNftDossier(token_id: string, include_expired?: boolean, sendViewer?: boolean): Promise<{
    nft_dossier: {
        unwrapped: boolean;
        owner: string;
        public_metadata: Metadata;
        private_metadata: Metadata;
        display_private_metadata_error: string;
        royalty_info: {
            decimal_places_in_rates: number;
            royalties: {
                recipient: string;
                rate: number;
            }[];
        };
        mint_run_info: {
            collection_creator: string;
            token_creator: string;
            time_of_minting: number;
            mint_run: number;
            serial_number: number;
            quantity_minted_this_run: number;
        };
        owner_is_public: boolean;
        public_ownership_expiration: Expiration;
        private_metadata_is_public: boolean;
        private_metadata_is_public_expiration: Expiration;
        token_approvals: {
            address: string;
            view_owner_expiration: Expiration;
            view_private_metadata_expiration: Expiration;
            transfer_expiration: Expiration;
        }[];
        inventory_approvals: {
            address: string;
            view_owner_expiration: Expiration;
            view_private_metadata_expiration: Expiration;
            transfer_expiration: Expiration;
        }[];
    };
  }>;
  setHandle(
    tokenId: string,
    handle: string
  ): Promise<
    ContractMessageResponse<{
      set_handle: {
        status: string;
      };
    }>
  >;
}

export interface Handle {
  token_id: string;
  handle: string;
}

const address = process.env.VUE_APP_ANONS_S1_CONTRACT;
const codeHash = process.env.VUE_APP_ANONS_S1_CONTRACT_HASH;

const anonsS1ContractDef: ContractDefinition = {
  queries:  {
    getHandles({ address, key }: Context, tokenIds: string[]): ContractQueryRequest {
      return {
        get_handles: {
          token_ids: tokenIds,
          viewer: {
            address: address,
            viewing_key: key,
          }
        }
      };
    },
    getHandlesWithPermit({ permit }: Context, tokenIds: string[]): ContractQueryRequest {
      const query = {
        get_handles: {
          token_ids: tokenIds,
        }
      };
      
      return {
        with_permit: {
          query,
          permit,
        }
      };
    },
  },
  messages: {
    setHandle(_: Context, tokenId: string, handle: string ): ContractMessageRequest {
      const handleMsg = {
        set_handle: {
          token_id: tokenId,
          handle,
        }
      };
      return { handleMsg, fees: 150_000 };
    }
  },
};

export const s1AnonsContract = 
  createContractClient<S1Contract>({
    id: 'anons-s1',
    at: address,
    definition: extendContract(snip721PermitDef, anonsS1ContractDef),
    codeHash,
  });
